import React from "react";
import ContactForm from "../components/ContactForm";
import AppBarPage from "../components/AppBarPage";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { siteName, topAppBarHeight } from "../constants/BaseConstants";

const verticalOffset = 35;

const pageTitle = "Contact " + siteName;

const container = {
  maxWidth: "750px",
  margin: "auto",
  marginTop: topAppBarHeight + verticalOffset + "px",
  padding: "0px 14px 0px 14px",
};

function Contact(): JSX.Element {
  return (
    <Box>
      <AppBarPage title={pageTitle} />
      <Box sx={container}>
        <ContactForm />
        <Box m={8} />
      </Box>
      <Helmet>
        {/* meta tags */}
        <title>ITANI & AI: Groundbreaking English Quran Translation</title>
        <meta
          name="description"
          content="Quran translated by Talal Itani & AI: A pioneering blend of human insight and technological advancement, offering unparalleled clarity, authenticity, and beauty"
        />
        <meta name="author" content="Talal Itani" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    </Box>
  );
}

export default Contact;
