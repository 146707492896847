import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { navigate } from "gatsby";
import Home from "@mui/icons-material/Home";
import { appBarColor, iconColor, topAppBarHeight } from "../constants/BaseConstants";
import { Box, CssBaseline, Typography } from "@mui/material";

const appBar = {
  backgroundColor: appBarColor,
};

const appBarContents = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  height: topAppBarHeight + "px",
  paddingLeft: "30px",
  paddingRight: "30px",
  "@media(min-width: 450px)": {
    paddingLeft: "55px",
    paddingRight: "55px",
  },
  "@media(min-width: 800px)": {
    paddingLeft: "100px",
    paddingRight: "100px",
  },
};

const icon = {
  fontSize: "1.7rem",
  color: iconColor,
};

const title = {
  marginLeft: "30px",
  fontSize: "22px",
  color: iconColor,
  paddingTop: "4px",
};

interface AppBarPageProps {
  title: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AppBarPage(props: AppBarPageProps): JSX.Element {
  function handleGoHome() {
    navigate("/");
  }

  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" sx={appBar}>
        <Box sx={appBarContents}>
          <IconButton size="medium" color="inherit" edge="start" onClick={handleGoHome}>
            <Home sx={icon} />
          </IconButton>
          <Typography sx={title}>{props.title}</Typography>
        </Box>
      </AppBar>
    </div>
  );
}

export default AppBarPage;
