import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

const containerTop = {
  display: "flex",
  flexWrap: "wrap",
};

const topField = {
  width: "233px",
};

const topFieldSpacing = {
  marginRight: "10px",
};

const textFieldMessage = {
  width: "100%",
  marginTop: "10px",
};

const submitButton = {
  marginTop: "15px",
  float: "right",
};

function ContactForm(): JSX.Element {
  return (
    <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
      <input type="hidden" name="form-name" value="contact" />
      <Box sx={containerTop}>
        <TextField
          required
          name="subject"
          label="Subject"
          defaultValue=""
          margin="dense"
          variant="outlined"
          autoComplete="off"
          sx={[topField, topFieldSpacing]}
        />
        <TextField
          required
          name="name"
          label="Name"
          defaultValue=""
          margin="dense"
          variant="outlined"
          autoComplete="name"
          sx={[topField, topFieldSpacing]}
        />
        <TextField
          required
          name="email"
          label="Email"
          defaultValue=""
          type="email"
          margin="dense"
          variant="outlined"
          autoComplete="email"
          sx={topField}
        />
      </Box>
      <TextField
        required
        name="message"
        label="Message"
        defaultValue=""
        sx={textFieldMessage}
        multiline
        rows="7"
        margin="dense"
        variant="outlined"
      />
      <Button variant="contained" color="primary" sx={submitButton} type="submit">
        &nbsp;Submit&nbsp;
      </Button>
    </form>
  );
}

export default ContactForm;
